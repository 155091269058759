import React from "react";
import { Box , useMediaQuery} from "@mui/material";
import "../css/AutoScrollGallery.css";



const AutoScrollGallery = ({images1, images2}) => {

  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:750px)')

  return (
    <Box sx={{maxWidth: (isMobileScreen || isTabScreen) ? "300px" : "1100px",}}>
      {/* Top Scrolling Container (Scrolls Left) */}
      <Box className="scroll1 top-scroll" sx={{ "--time": "26s" }}>
        <Box className="scroll-container">
          {images1.concat(images1).map((src, index) => (
            <Box key={index} component="img" src={src} alt={`Image ${index}`} className="scroll-image" />
          ))}
        </Box>
      </Box>

      {/* Bottom Scrolling Container (Scrolls Right) */}
      <Box className="scroll1 bottom-scroll" sx={{ "--time": "26s" , mt:"3px"}}>
        <Box className="scroll-container">
          {images2.concat(images2).map((src, index) => (
            <Box key={index} component="img" src={src} alt={`Image ${index}`} className="scroll-image" />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AutoScrollGallery;
