import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DeepLinks = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // If on Android, display Android-specific link
            window.location.href = "https://play.google.com/store/apps/details?id=com.buzz.project";
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            // If on iOS, display iOS-specific link
            window.location.href = "https://apps.apple.com/in/app/buzz-meet-people-around-you/id6627343452";
        } else {
            // For other devices (e.g., PC), redirect to the home page
            navigate('/'); // Adjust this to your actual home route
        }
    }, [navigate]);

    return null;
};

export default DeepLinks;