import React from 'react'
import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import logo from '../../assets/images/buzz_logo.svg'
import cart from '../../assets/images/cart.svg'
import person from '../../assets/images/person.svg'

const link = document.createElement("link");
link.href = "https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
link.rel = "stylesheet";
document.head.appendChild(link);

const BuzzHeader = ({onSelect}) => {

    const isMobileScreen = useMediaQuery('(max-width:500px)');
    const isTabScreen = useMediaQuery('(max-width:750px)')

    return (

        <Box sx={{
            flexDirection: 'row', display: 'flex', position: 'fixed', background: '#ffffff', borderBottom: "1px solid #aaaaaa20", alignSelf: 'stretch',
            width: "100%", zIndex: 10, alignItems: 'center', justifyContent: 'center'
        }} >

            <Box sx={{
                flexDirection: 'row', display: 'flex', alignItems: 'center', paddingY: "20px", marginX: "10px",
                justifyContent: 'space-between', flex: 1, maxWidth: "900px",
            }}>
                <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                    <img style={{ width: 85, height: 22 }} src={logo} />


                </Box>

                {
                    (!isMobileScreen && !isTabScreen) && (
                        <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                            <Typography onClick={() => onSelect(1)} style={{
                                color: "#000000", fontSize: "13px", marginLeft: "50px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1,
                                fontWeight: 500
                            }} >Home</Typography>
                            <Typography onClick={() => onSelect(2)} style={{
                                color: "#000000", fontSize: "13px", marginLeft: "25px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1,
                                fontWeight: 500
                            }} >How to Buzz</Typography>
                            <Typography onClick={() => onSelect(3)} style={{
                                color: "#000000", fontSize: "13px", marginLeft: "25px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1,
                                fontWeight: 500
                            }} >Features</Typography>
                            <Typography onClick={() => onSelect(4)} style={{
                                color: "#000000", fontSize: "13px", marginLeft: "25px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1,
                                fontWeight: 500
                            }} >Buzz Partners</Typography>
                            <Typography onClick={() => onSelect(5)} style={{
                                color: "#000000", fontSize: "13px", marginLeft: "25px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1,
                                fontWeight: 500
                            }} >Gallery</Typography>

                        </Box>
                    )
                }



                <Box onClick={() => onSelect(6)} sx={{ border: '1.5px solid #706dea', marginLeft: "25px", paddingX: "15px", paddingY: "9px", borderRadius: "3px", cursor: 'pointer', }}>
                    <Typography style={{ color: "#706dea", fontSize: "13px", fontFamily: "Poppins", lineHeight: 1 }} >Contact us</Typography>
                </Box>

            </Box>
        </Box>
    )
}

export default BuzzHeader;