import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import "../css/AutoScrollBp.css";



const AutoScrollBuzzPartners = ({images}) => {
  const isMobileScreen = useMediaQuery('(max-width:500px)');
  const isTabScreen = useMediaQuery('(max-width:750px)')
  return (
    <Box sx={{mt: "50px", maxWidth: isMobileScreen ? "100%" : "900px"}}>
      {/* Top Scrolling Container (Scrolls Left) */}
      <Box className="scroll top-scroll" sx={{ "--time": "26s" }}>
        <Box className="scroll-container">
          {images.concat(images).map((src, index) => (
            <Box key={index} component="img" src={src} alt={`Image ${index}`} className="scroll-image1" />
          ))}
        </Box>
      </Box>

      {/* Bottom Scrolling Container (Scrolls Right) */}
      {/* <Box className="scroll bottom-scroll" sx={{ "--time": "26s" , mt:"3px"}}>
        <Box className="scroll-container">
          {images2.concat(images2).map((src, index) => (
            <Box key={index} component="img" src={src} alt={`Image ${index}`} className="scroll-image" />
          ))}
        </Box>
      </Box> */}
    </Box>
  );
};

export default AutoScrollBuzzPartners;
