import React, {useState} from 'react';
import { Container, Grid, Typography, Button, Box, TextField, useMediaQuery, Divider } from '@mui/material';
import { styled } from '@mui/system';
import BuzzHeader from '../Header/BuzzHeader';
import AutoScrollGallery from '../Items/AutoScrollGallery';
import BASE_URL from '../ApiConfig';


// Styled components
const DownloadButton = styled(Box)({
    margin: '10px',
    padding: '10px 20px',
    borderRadius: '3px',
    border: "1px solid #303030",
    cursor: 'pointer',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex'
});

const AppImage = styled('img')({
    maxWidth: '100%',
    height: 'auto',
});

const HeroSection = () => {
    return (
        <Box sx={{
            flexDirection: 'column', alignItems: 'center', alignSelf: 'stretch', display: 'flex', minHeight: "200px",

        }}>
            <Box sx={{ paddingX: '20px', paddingTop: "120px", maxWidth: '900px', margin: 'auto' }}>
                <Typography variant="h5" mb={"50px"} style={{ textDecoration: 'underline', fontFamily: 'poppins', fontWeight: 600 }} align="center" gutterBottom>
                    Terms of Service
                </Typography>
                <Typography variant="h7" style={{ fontFamily: 'poppins', fontWeight: 500 }} align="left" gutterBottom>
                    KONNXT PRIVATE LIMITED<br />U72900WB2022PTC253943<br />
                    135-N, KARUNAMOYEE GHAT RD REF-C.PYNE3/5/12KOLKATA WB 700082<br /><br />
                    End User License Agreement (“EULA”)
                </Typography>
                <Divider sx={{ mb: 2, mt: 2 }} />

                <Typography fontFamily={"poppins"} fontWeight={600} variant="body1" paragraph>
                    THIS END USER LICENSE Agreement (“Agreement”) is aconsensusbetween KONNXT PRIVATE LIMITED, (“Company,” “we,” “us” or “our”) and you or the entity that you represent for the “BUZZ” digital Application(the“Software”). PLEASE READ THIS AGREEMENT CAREFULLY. IT CONTAINS IMPORTANT TERMS THAT AFFECT YOU AND YOU’RE USE OF THE SOFTWARE. BY INSTALLING, COPYING OR USING THE SOFTWARE, YOU AGREE TO BE BOUNDBY THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO THESE TERMS, DONOT INSTALL, COPY, OR USE THE SOFTWARE. IF YOU ARE ACCESSINGOR USING THE SOFTWARE ON BEHALF OF ANY ENTITY OR OTHERWISE IN PERFORMANCE OF SERVICES FOR ANY ENTITY, YOU REPRESENT AND WARRANT THAT YOU ARE AUTHORIZED TO ACCEPT THIS AGREEMENT ON SUCH ENTITY'S BEHALF, THAT ACCEPTANCE OF THIS AGREEMENT AND USE OF THIS SOFTWARE WILL NOT VIOLATE ANY AGREEMENT YOU HAVE WITH SUCH ENTITY, AND THAT SUCH ENTITY AGREES TO INDEMNIFY YOU AND COMPANY FOR VIOLATIONS OF THIS AGREEMENT.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    1. SOFTWARE LICENSE
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    Subject to the terms and conditions of this Agreement and duringthetermof
                    this Agreement, the Company hereby grants you a non-exclusive, non-sublicensable, non-transferable license to [install and use] [stream, access, download and use] one copy of the Software in object code formonlyonasingle [personal computing device] [Mobile] [network server] solely tousetheservices made available by Company (the “Company Services”) for your
                    own personal or internal business use (the “Permitted Purpose”). Any Softwarethat updates, supplements or replaces the original Software is governedbythis Agreement unless separate license terms accompany suchupdate, supplement or replacement, in which case such separate terms will governinthe event of a conflict with this Agreement or as otherwise providedinsuchseparate terms.                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    2. LICENSE LIMITATIONS
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    Section 1 states the entirety of your rights with respect to the Software, andwe reserve all rights not expressly granted to you in this Agreement. Withoutlimiting the foregoing, you will not do, or authorize or permit any thirdpartytodo, any of the following:</Typography>


                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'disc', pl: 8 }}>
                    <li>Distribute, sub-license, sell, assign, or otherwise transfer or make available the Software;</li>
                    <li>Use the Software for any purpose other than the Permitted Purpose;</li>
                    <li>Reverse engineer, decompile, disassemble or otherwise attempt to discover or re-create the source code for the Software;</li>
                    <li>Modify, adapt, alter, improve or create any derivative works of the Software;</li>
                    <li>Connect the Software with any other online services or use the Software in conjunction with other software or services not provided by or permitted by Company [in connection with the Company Services];</li>
                    <li>Remove, circumvent or create or use any workaround to any copy protection or security feature in or relating to the Software; or</li>
                    <li>Remove, alter or obscure any copyright, trademark or other proprietary rights notice on or in the Software.</li>
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    3. OWNERSHIP AND CONFIDENTIALITY
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    The Software is licensed, not sold, to you. We or our licensors own all right, titleand interest in and to the Software, including all copyright, patent andother
                    intellectual property or other proprietary rights in the Software. Notitletoor
                    ownership of the Software or any associated intellectual property or
                    proprietary rights are transferred to you by this Agreement. You also acknowledge that when you download, install or use theSoftware, we have the right to automatically collect information on your useof theSoftware. We may also require that you provide information regarding yourself in order to download, install or use the Software or certain features. All information collected from you in connection with the Software may be used by us in compliance with our Privacy & Data recovery Policy [PROVIDELINK]. By your using, installing, downloading or providing information, you
                    consent to the Privacy Policy and our use of your information in compliance thereof.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="body1" paragraph>
                    YOU ARE EXPRESSLY PROHIBITED FROM DISCLOSING THE SOFTWARE TO ANY PERSON OR ENTITY OR PERMITTING ANY PERSON OR ENTITY ACCESS TOORUSEOF THE SOFTWARE OTHER THAN IN ACCORDANCE WITH THIS AGREEMENT.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    4. INTELLECTUAL PROPERTY RIGHTS
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    You shall obtain no rights to the Software. The Company retains all intellectual
                    property rights to the Software, updates, documentation, and all data enclosed in it, including, but not limited to, copyright, trademarks andother
                    rights, titles and interests in the Software. You are not permitted to publish the Software without the explicit, written consent of the Company. This also applies to any documentation delivered by Company. You may not reverseengineer, decompile, or disassemble the Software except when otherwise allowed by mandatory statutory provisions. You shall always notify the Company in writing in advance of such acts. Upon an infringement of the Company’s intellectual property rights, Company shall been titled to terminate this Agreement for cause with immediate effect.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    5. DISCLAIMERS
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    THE SOFTWARE IS PROVIDED TO YOU FREE OF CHARGE AND IS PROVIDEDONAN “AS IS” BASIS AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. COMPANY ANDITS LICENSORS DISCLAIM ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED. WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. COMPANY DOES NOT REPRESENT OR WARRANT THAT THE SOFTWARE IS FREE OF BUGS, ERRORS, VIRUSES OR OTHER DEFECTS OR THAT THE SOFTWARE WILL TRANSMIT DATA IN A SECURE MANNER. COMPANY AND ITS LICENSORS SHALL HAVE NOLIABILITYOFANY KIND FOR THE USE OF OR INABILITY TO USE THE SOFTWARE. COMPANY AND ITS LICENSORS PROVIDES ANY WARRANTY OR REPRESENTATIONS OF ANY KIND THAT THE SOFTWARE WILL OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, ACHIEVE ANY KINDOF INTENDED PURPOSE, BE COMPATIBLE WITH ANY OTHER SOFTWARE OR SYSTEM, OR HAVE ERRORS THAT CAN BE CORRECTED.

                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} mt={"20px"} ml={"18px"} variant="body1" paragraph>
                    Some jurisdictions do not allow the exclusion of implied warranties or
                    limitations on how long an implied warranty may last. To the extent that those laws apply to this Agreement, the exclusions and limitations set forth above may not apply.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    6. INDEMNIFICATION
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    You will defend, indemnify and hold harmless Company andits affiliates,
                    independent contractors, service providers, suppliers, partners, resellers, distributors and consultants, and their respective directors, officers, employees and agents (collectively, the “Company Parties”) from and against any third party claims, suits or actions and any resulting damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related to:

                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'disc', pl: 8 }}>
                    <li>Your use of, or inability to use, the Software; or</li>
                    <li>Your violation of any terms of this Agreement</li>
                </Typography>

                <Typography fontFamily={"poppins"} mt={"20px"} fontWeight={600} variant="h6" gutterBottom>
                    7. LIMITATION OF LIABILITY
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    IN NO EVENT SHALL ANY OF THE COMPANY PARTIES BE LIABLE FOR ANYDIRECT, SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITSOR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO USE THE SOFTWARE, EVEN IF A COMPANY PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE COMPANY PARTIES, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITYOR OTHER THEORY, ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE SOFTWARE EXCEED THE COMPENSATION YOU HAVE PAID, IF ANY, TO COMPANY FOR THE SOFTWARE. <br /><br /> FURTHER YOU OR ANY ASSIGNEE - THE USER OF THE SOFTWARE SHALL AND WILL BE THE SOLEY LIABLE AND RESPONSIBLE FOR ANY ACT OR OMMISSION EXECUTED AND ANY ISSUE OR EVENT INCIDENTAL AND COSEQUENTAL TO SUCH ACTIVITY THE COMPANY BEARS NO LIABILITY WHATSOEVER
                    <br /> <br />Some jurisdictions do not allow the exclusion or limitation of certain damages. To the extent that those laws apply to this Agreement, the exclusions and limitations set forth above may not apply

                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    8. TERMINATION
                </Typography>



                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    Company may terminate this Agreement at any time, with or without cause,
                    immediately upon notice to you. [Company may also discontinue the Company Services with which the Software is used, in which case this Agreement will terminate automatically without notice to you.] You may terminate this Agreement [by cancelling your Company Services account opened in connection with your use of the Software and] deleting all copies of the Software in your possession or control. In the event of the termination of
                    this Agreement for any reason:
                    <br />
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'disc', pl: 8 }}>
                    <li>The license granted to you in this Agreement will terminate;</li>
                    <li>Must immediately cease all use of the Software and destroy or erase all copies of the Software in your possession or control; and</li>
                    <li>Sections 3 (Ownership), 4 (Disclaimers), 5 (Indemnification), 6(Limitation of Liability), 7 (Termination), 8 (Governing Law;
                        Jurisdiction) and 9 (General) will survive any such termination. [Further, in the event the agreement governing your use of the Company Services with which the Software is used is terminated for
                        any reason, this Agreement will terminate automatically without notice to you.]</li>
                    <li>In the event the company finds any suspicious activity from your
                        part to have malicious act or intent causing any harm to others either physically or emotionally or physiologically, then it holds all
                        utmost rights to detach you from the software and all its applications and initiate subsequent legal action if necessary.</li>
                </Typography>

                <Typography fontFamily={"poppins"} mt={"20px"} fontWeight={600} variant="h6" gutterBottom>
                    9. EVALUATION AND FEEDBACK
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    You will at your own risk and expense use reasonable efforts to provide Company with reasonable information and feedback (including, without limitation, questions, comments, suggestions, or the like) regarding the performance, features, functionality and your overall experience using the Software (“Feedback”) and will make yourself available to Company on a reasonable basis for this purpose. You will not provide any such Feedback to any third party without Company’s prior written consent in each instance. You hereby grant to Company an exclusive, royalty-free, irrevocable, perpetual worldwide right and license to reproduce, use, disclose, exhibit, display, transform, create derivative works and distribute any such Feedback without limitation. Further, we will be free to use any ideas, concepts, know-how or techniques contained in such Feedback for any purpose whatsoever,
                    including, without limitation, developing, making, marketing, distributing and selling products and services incorporating such Feedback. Wewill have no obligation to consider, use, return or preserve any Feedback you provide to us. Any Feedback you provide to us may or may not be treated confidentially by us, and we will have no obligation or liability to you for the use or disclosure of any Feedback. You should not to expect any compensation of any kind from us with respect to your Feedback.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    10. GOVERNING LAW; JURISDICTION
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    Unless expressly prohibited by local law, this Agreement is governed by the laws of the State of India, without regard to any conflict of law principles to
                    the contrary. You hereby irrevocably consent to jurisdiction of the state and courts located in India with respect to any proceeding regarding this Agreement or the Software. The 1980 UN Convention for the International
                    Sale of Goods or any successor thereto does not apply to this Agreement. You will not prosecute any action suit, proceeding or claim arising under or
                    by reason of this Agreement or the Software except in such courts.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    11. GENERAL
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} ml={"18px"} variant="body1" paragraph>
                    <span style={{ fontWeight: 600 }}>{"a)"} Legal Compliance;</span> Export Restrictions. You represent andwarrant that
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'number', pl: 8 }}>
                    <li>You are not located in a country that is subject to aUNembargo, or that has been designated by the Indian government as a “terrorist supporting” country as per Financial Intelligence Unit -India (FIU-IND) Ministry of Finance and UAPA Ministry of Home Affairs; and</li>
                    <li style={{ marginTop: "5px" }}>You are not listed on any Indian government’s list of prohibitedor
                        restricted parties. The Software and related technology are subject to applicable Indian export laws and regulations. You must comply with all applicable laws and regulations, including without limitation all applicable India and international exportlaws and regulations, with respect to the Software and related technology. Without limitation, you may not export, re-export or
                        otherwise transfer the Software or related technology, without aIndian government license:</li>
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" mt={"10px"} component="ul" sx={{ listStyle: 'disc', pl: 16 }}>
                    <li>To any person or entity on any United States export control list;</li>
                    <li>To any country subject to a United States sanctions; or</li>
                    <li>For any prohibited end use</li>
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} mt={"20px"} ml={"18px"} variant="body1" paragraph>
                    <span style={{ fontWeight: 600 }}>{"b)"} Severability;</span> If any provision of this Agreement is held by acourt of
                    competent jurisdiction to be invalid, illegal, or unenforceable, the remainder of this Agreement will remain in full force and effect, and the remaining provisions will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} mt={"20px"} ml={"18px"} variant="body1" paragraph>
                    <span style={{ fontWeight: 600 }}>{"c)"} Assignment;</span> You may not assign or otherwise transfer this Agreement, or assign, sublicense or otherwise transfer any of your rights under this Agreement without the prior written consent of Company, and any attempted assignment without such consent will be void.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} mt={"20px"} ml={"18px"} variant="body1" paragraph>
                    <span style={{ fontWeight: 600 }}>{"d)"} Advocate's Fees;</span> In any action or proceeding to enforce rights under
                    this Agreement, the prevailing party will be entitled to recover costs and reasonable advocate’s fees.</Typography>

                {/* Add further sections similarly, breaking each into Typography components with Box as needed */}
            </Box>


        </Box>
    );
};


const ContactUsForm = () => {

    const isMobileScreen = useMediaQuery('(max-width:500px)');
    const isTabScreen = useMediaQuery('(max-width:1150px)')

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');



    const submitDetails = async () => {
        try {
            const response = await fetch('https://api.konnxt.com/web/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, email, message })
            });

            const json = await response.json();

            if (json.success) {
                // Handle successful submission (e.g., display a success message)
                alert('Your message has been sent successfully!');
                setName("")
                setEmail("")
                setMessage("")
            } else {
                // Handle errors (e.g., display an error message)
                alert(json.response);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An unexpected error occurred. Please try again later.');
        }
    };

    return (
        <Box sx={{
            flexDirection: 'column', display: 'flex', background: '#ffffff', borderRadius: "15px", padding: isMobileScreen ? "15px" : "25px", alignSelf: 'stretch',
            marginInline: isMobileScreen ? "10px" : "0px", width: isMobileScreen ? "auto" : isTabScreen ? "400px" : "500px"
        }}>

<Typography style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "12px", color: "#303030", marginLeft: "3px", marginTop: "30px" }}>Name</Typography>
            <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                    borderColor: '#ffffff', background: '#ffffff', borderRadius: "4px", marginTop: "5px", alignSelf: 'stretch'
                }}
                inputProps={{
                    style: {
                        paddingTop: "13px", paddingBottom: "13px", paddingInline: "15px", borderRadius: "4px", fontFamily: 'poppins', fontSize: '14px',
                    }
                }}
                inputMode={'text'}
                placeholder='Enter your name' 
            />

            <Typography style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "13px", color: "#303030", marginLeft: "3px", marginTop: "20px" }}>Email</Typography>
            <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                    borderColor: '#ffffff', background: '#ffffff', borderRadius: "4px", marginTop: "5px",
                }}
                inputProps={{
                    style: {
                        paddingTop: "13px", paddingBottom: "13px", paddingInline: "15px", borderRadius: "4px", fontFamily: 'poppins', fontSize: '14px',
                    }
                }}
                inputMode={'email'}
                placeholder='Enter your email address'
            />

            <Typography style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "13px", color: "#303030", marginLeft: "3px", marginTop: "20px" }}>Message</Typography>
            <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                    borderColor: '#ffffff', background: '#ffffff', borderRadius: "4px", marginTop: "5px",
                    verticalAlign: 'top', textAlign: 'left', flex: 1,
                }}
                inputProps={{
                    style: {
                        paddingTop: "13px", paddingBottom: "13px", borderRadius: "4px", fontFamily: 'poppins', fontSize: '14px',
                        textAlign: 'left', verticalAlign: 'top', minHeight: "130px"
                    }
                }}
                multiline
                placeholder='Enter your message'
            />

            <Typography
                onClick={submitDetails}
                style={{
                    cursor: 'pointer',
                    color: '#ffffff', fontFamily: 'poppins', fontSize: "14px", background: '#333946', borderRadius: "4px", fontWeight: 500,
                    marginTop: "40px", textAlign: 'center', paddingBottom: "10px", paddingTop: "10px"
                }} 
            >
                Submit
            </Typography>

        </Box>
    )
}


const BuzzContactUs = ({ route }) => {

    const isMobileScreen = useMediaQuery('(max-width:1000px)');
    const isTabScreen = useMediaQuery('(max-width:1500px)')
    return (
        <Box sx={{ mt: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', background: "#333946", paddingTop: isMobileScreen ? "40px" : "60px" }}>


            <Box sx={{
                alignSelf: 'stretch', marginInline: isMobileScreen ? "20px" : isTabScreen ? "50px" : "250px", flexDirection: 'row', display: 'flex',
                marginTop: isMobileScreen ? "0px" : "40px", marginBottom: "80px",
                justifyContent: isMobileScreen ? 'center' : "space-between", alignItems: 'center',
            }}>

                <Box sx={{ flexDirection: 'column', alignItems: isMobileScreen ? 'center' : 'flex-start', display: 'flex', }}>

                    <Typography fontFamily={"poppins"} fontWeight={500} variant={isMobileScreen ? "h5" : "h4"} component="h2" gutterBottom textAlign={isMobileScreen ? "center" : "left"} color={"#ffffff"}>
                        Get in touch with us
                    </Typography>
                    <Typography textAlign={isMobileScreen ? "center" : "left"} fontFamily={"poppins"} fontWeight={300} color={"#ffffff90"} fontSize={isMobileScreen ? "13px" : "15px"} component="h2" maxWidth={"350px"}>
                        Fill out the form below or schedule a meeting with us at your convenience.
                    </Typography>

                    <Box sx={{
                        height: "1px", alignSelf: 'stretch', background: "#ffffff10", marginTop: "30px", maxWidth: isMobileScreen ? "100%" : "300px",
                        marginBottom: isMobileScreen ? "20px" : "0px"
                    }} />

                    {
                        isMobileScreen && (
                            <ContactUsForm />
                        )
                    }

                    <Typography fontFamily={"poppins"} fontWeight={400} color={"#ffffff"} fontSize={"16px"} mt={"30px"} component="h2" maxWidth={"500px"}>
                        You can also contact us via following below
                    </Typography>


                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginTop: "30px", cursor: 'pointer' }}>
                        <img src={require("../../assets/images/email.svg")} style={{ width: '16px', height: "16px", filter: "invert()", marginTop: '2px' }} />
                        <Typography style={{
                            fontFamily: 'poppins',
                            fontSize: '14px',
                            fontWeight: 300,
                            maxWidth: "310px",
                            color: '#e5e5e5',
                            marginLeft: "10px"
                        }} >support@konnxt.com</Typography>
                    </Box>


                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginTop: "20px", cursor: 'pointer' }}>
                        <img src={require("../../assets/images/location.svg")} style={{ width: '16px', height: "16px", filter: "invert()", marginTop: '2px' }} />
                        <Typography style={{
                            fontFamily: 'poppins',
                            fontSize: '14px',
                            fontWeight: 300,
                            maxWidth: "310px",
                            color: '#e5e5e5',
                            marginLeft: "10px",
                            textAlign: isMobileScreen ? "center" : "left"
                        }} >135/N, Karunamoyee Ghat Rd, Sarat Pally, Kazipara, Kolkata, West Bengal</Typography>
                    </Box>


                    <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: "40px" }}>
                        <img onClick={() => {
                            window.open('https://www.facebook.com/buzz.konnxt?mibextid=ZbWKwL', '_blank');
                        }} src={require("../../assets/images/facebook.svg")} style={{
                            width: '24px', height: "24px", filter: "invert()", marginTop: '2px', marginRight: "25px",
                            cursor: 'pointer'
                        }} />
                        <img onClick={() => {
                            window.open('https://www.instagram.com/buzzonspot/', '_blank');
                        }} src={require("../../assets/images/insta.svg")} style={{
                            width: '24px', height: "24px", filter: "invert()", marginTop: '2px',
                            cursor: "pointer"
                        }} />


                    </Box>

                    <Box sx={{ marginTop: isMobileScreen ? '10px' : '50px', flexDirection: isMobileScreen ? 'column' : 'row', display: 'flex', marginLeft: isMobileScreen ? "0px" : "-10px" }}>
                        <DownloadButton onClick={() => {
                            window.open('https://apps.apple.com/in/app/buzz-meet-people-around-you/id6627343452', '_blank');
                        }} style={{
                            background: '#ffffff'
                        }} variant="contained" color="#303030">
                            <img src={require("../../assets/images/apple.svg")} style={{ width: '15px', height: "15px" }} />
                            <Typography style={{
                                fontFamily: 'poppins',
                                fontSize: isMobileScreen ? '13px' : '13px',
                                fontWeight: 400,
                                color: '#000000',
                                marginLeft: "10px"
                            }} >Download from App Store</Typography>
                        </DownloadButton>
                        <DownloadButton onClick={() => {
                            window.open('https://play.google.com/store/apps/details?id=com.buzz.project', '_blank');
                        }} variant="contained" style={{
                            border: "1px solid #ffffff"
                        }} color="#303030">
                            <img src={require("../../assets/images/play-store-dark.svg")} style={{ width: '15px', height: "15px", filter: "invert()" }} />
                            <Typography style={{
                                fontFamily: 'poppins',
                                fontSize: isMobileScreen ? '13px' : '13px',
                                fontWeight: isMobileScreen ? 500 : 400,
                                marginLeft: "10px",
                                flex: 1,
                                textAlign: 'center',
                                color: 'white'
                            }}>Get it on Google Play</Typography>
                        </DownloadButton>

                    </Box>



                    <Box sx={{ height: "1px", alignSelf: 'stretch', background: "#ffffff10", marginTop: "30px", maxWidth: "300px" }} />

                    <Box sx={{ marginTop: "20px", flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                        <Typography
                            onClick={() => {
                                window.location.href = `${BASE_URL}/terms-of-service`
                            }}
                            style={{ cursor: 'pointer', color: "white", textDecoration: "underline", fontFamily: 'poppins', fontWeight: 300, fontSize: "12px", marginRight: "20px" }} >
                            Terms of Service</Typography>
                        <Typography
                            onClick={() => {
                                window.location.href = `${BASE_URL}/privacy-policy`
                            }}
                            style={{ color: "white", cursor: 'pointer', textDecoration: "underline", fontFamily: 'poppins', fontWeight: 300, fontSize: "12px" }} >
                            Privacy Policy</Typography>

                    </Box>


                </Box>

                {
                    !isMobileScreen && (
                        <ContactUsForm />
                    )
                }


            </Box>

            <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginBottom: "10px" }}>
                <Typography sx={{ color: '#ffffff90', fontFamily: 'poppins', fontWeight: 300, fontSize: "12px", }}>
                    Copyright © 2022. Powered by </Typography>

                <img style={{ width: "40px", height: "20px", marginLeft: "5px" }} src={require("../../assets/images/konnxtLogo.png")} />
            </Box>


        </Box>
    );
};





const TermsOfService = () => {
    return (
        <>
            <BuzzHeader />
            <Box sx={{ height: "1px", alignSelf: 'stretch', background: '#aaaaaa20' }} />
            <HeroSection />
            <BuzzContactUs />
        </>
    );
};

export default TermsOfService;
