import React, {useState} from 'react';
import { Container, Grid, Typography, Button, Box, TextField, useMediaQuery, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import BuzzHeader from '../Header/BuzzHeader';
import BASE_URL from '../ApiConfig';


const DownloadButton = styled(Box)({
    margin: '10px',
    padding: '10px 20px',
    borderRadius: '3px',
    border: "1px solid #303030",
    cursor: 'pointer',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex'
});

const HeroSection = () => {
    return (
        <Box sx={{
            flexDirection: 'column', alignItems: 'center', alignSelf: 'stretch', display: 'flex', minHeight: "200px",

        }}>
            <Box sx={{ paddingX: '20px', paddingTop: "120px", maxWidth: '900px', margin: 'auto' }}>
                <Typography variant="h5" mb={"50px"} style={{ textDecoration: 'underline', fontFamily: 'poppins', fontWeight: 600 }} align="center" gutterBottom>
                    Privacy Policy
                </Typography>

                <Divider sx={{ mb: 2, mt: 2 }} />

                <Typography fontFamily={"poppins"} fontWeight={600} variant="body1" paragraph>
                    We appreciate & value the trust that you have placed on us. Our Privacy &Data Recoverypolicy demonstrates our commitment to the privacy of the users for our Site(<span
                        onClick={() => window.open("https://www.konnxt.com", "_blank")}
                        style={{ cursor: 'pointer', color: '#706dea', textDecoration: 'underline' }} >www.konnxt.com</span> and its allied sites) and BUZZ App (Android, iOS, Windows app, & any other product owned by KONNXT PRIVATE LIMITED). Please read the following to learn more about our Privacy and Data Recovery policy, and how we treat personally identifiable information collected from our visitors and users. Please note that by visiting this Site or using BUZZ App you agree to be bound by the terms and conditions of this Privacy and Data Recovery Policy which is a legally binding agreement between you and us, KONNXT PRIVATE LIMITED, the owner of the Site & BUZZ App ("Company"). If you do not agree, please do not use or access this Site or BUZZ App. By mere use or access of the Site or BUZZ App, you expressly consent to our use and disclosure of your personal information in accordance with this Policy. This Policy is incorporated into and subject to the Terms of Use.                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} style={{ textDecoration: 'underline' }} mt={"40px"} variant="h7" gutterBottom>
                    PRIVACY & DATA RECOVERY POLICY
                    Of
                    KONNXT PRIVATE LIMITED
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" paragraph>
                    This Privacy and Data Recovery policy (here in after referred to as the Policy) has been framed and is being issued here with for implementation in the Company. The Policy sets forth to enforce the entire Privacy and Data Recovery framework & it’s governance in the Company. Here the intent of the Policy is to enhance Confidentiality, safety, security, efficiency in processes, data exchange and recovery involving leadership support, organizational structure and strategy through well-defined responsibilities of the Board & executive management</Typography>
                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    Objectives
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'disc', pl: 8 }}>
                    <li>To provide Security oriented services and support to facilitate innovative use of
                        technology for better company’s decision making and for providing better services to the Subscribers</li>
                    <li>More and More Integration of security and recovery of Data into the operation and other functions in line with the overall business objectives of the company</li>
                    <li>Provide infrastructure to application subscribers through a more secured platform, cyber security, personalized and timely access of information, services and support.</li>
                    <li>Clearly Defining Roles and responsibilities of the company’s board, management andits customers in enabling corporate governance, leadership support, organizational
                        structure to ensure Information systems sustains and extends strategies toachieve overall objectives</li>
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    SCOPE AND MANDATE
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" paragraph>
                    The Mandate of the policy is to set out Company’s approach towards its own information security management systems. <br /><br />The documents in the Privacy Systems Policy apply to all information and assets which are owned by the company irrespective of any form, used by the company for business orany other purposes or which are connected to any networks managed by the company. <br /><br />The documents in the Privacy systems Policy set apply to all subscribers of the company and any other individual who may process information on behalf of the company. <br /><br />The scope of the Privacy Systems Policy is concerned with the management and security of
                    the company’s information assets and the use made of the assets by its employees andother individuals who may legitimately process company’s information on its behalf.
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} mt={"40px"} variant="h7" gutterBottom>
                    This policy and the Framework apply to:
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'disc', pl: 8 }}>
                    <li>Anyone Individual within the company who accesses company’s information assets or technology. This includes employees or any other Individual</li>
                    <li>Technologies or services used to access or process company’s Information assets</li>
                    <li>Information assets processed in relation to Government, regulators or any other entity’s activity or function, including by, for, or with, external parties</li>
                    <li>Information assets that are stored by the company or an external service provider on behalf of the company</li>
                    <li>Information that is transferred from and/or to the government, Regulators or any other entity for a functional purpose</li>
                    <li>3rd party, public civic or other information that the company stores, curates or uses on behalf of another party</li>
                    <li>Internal and/or external systems that are used to process, transfer or store company’s information</li>
                </Typography>

                <Divider sx={{ my: 2 }} />


                <Typography fontFamily={"poppins"} fontWeight={700} variant="h5" gutterBottom>
                    PART 1
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    WHAT THIS PRIVACY POLICY COVERS?
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" paragraph>
                    This privacy policy covers treatment of personally identifiable information collected by this Site and BUZZ App</Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'number', pl: 8 }}>
                    <li ><span style={{ fontWeight: 600 }}>Collection and Use of Personal Information</span><br />
                        {"a)"} While using BUZZ app, we would collect & store following information:<br /><br />
                        <span style={{ fontWeight: 600 }}>I. Email Addresses</span><br />
                        We automatically read & collect your email addresses available on your phone. We need email addresses to send you email for updates, Invoices & other communications. We do not share this with any third parties.

                        <br /><br />
                        <span style={{ fontWeight: 600 }}>II. Phone Number</span><br />
                        We collect phone number only when you explicitly give it to us. This is needed to alert text messages etc. Phone Number is also needed for unique identity to help us give you BUZZ points in your BUZZ closed wallet within the app.

                        <br /><br />
                        <span style={{ fontWeight: 600 }}>II. SMS or Message Logs</span><br />
                        We may store the log of all the SMS sent to a you on behalf of the Buzz app or Konnxt Pvt Ltd on our server to notify you with status changes or to inform you about other services.

                        <br /><br />
                        <span style={{ fontWeight: 600 }}>III. Device ID</span><br />
                        We may store your device id such as IMEI number to help us uniquely identify you. This is needed as many users carry more than one devices to access BUZZ app

                        <br /><br />
                        <span style={{ fontWeight: 600 }}>IV. GPS location & City</span><br />
                        We may track your current GPS location & city to offer you personalized news & information such as other BUZZ users around your location.

                        <br /><br />
                        <span style={{ fontWeight: 600 }}>V. Device Make, operator name</span><br />
                        We collect device type for you to optimize certain features for your device type.

                        <br /><br />
                        <span style={{ fontWeight: 600 }}>VI. Network type</span><br />
                        We may collect this information as few network types behave differently at times

                        <br /><br />
                        <span style={{ fontWeight: 600 }}>VII. Operating System Version Code & Version Name</span><br />
                        We may collect operating system version name & version code to optimize certain features for your OS version.

                        <br /><br />
                        <span style={{ fontWeight: 600 }}>IX. App Version code and app version name</span><br />
                        We may collect app version code and app version name to help support you better.



                    </li>






                    <li style={{ marginTop: "30px" }}>The data should be collected in the format for Signup. The above information may also be used for internal analysis on our users' demographics, interests, and behavior patterns to help us better understand, protect and serve our users. This information
                        is analyzed on an aggregated basis. (An extract of data collection procedure is presented in scenario 1)<br /><br />
                        <span style={{ fontWeight: 600 }}>None of this information is shared with any third parties & may be periodically deleted from our servers when not needed. Provided Company do shares</span> data with third party for advertisement and usage purposes all routine but does not discloses sensitive information like name and buzz-name. But other information is shared among backend advertisers.</li>

                    <li style={{ marginTop: "30px" }} >You can visit the Site without revealing any personal information. However, once you register yourself to avail our services, you will be sharing your personal information with us. This helps us provide you a safe and customized experience on our site. Wemay automatically track certain information about you based upon your behavior onour Site.</li>
                    <li style={{ marginTop: "30px" }}>
                        For users accessing our Site, the information collected may include the source URL, destination URL, your operating system,
                        device, browser information, andyour IP address. We use data collection devices such as "cookies" on certain pages of the Site to help analyze our web page flow,
                        measure promotional effectiveness, and promote trust and safety. "Cookies" are small
                        files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a "cookie".
                        We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests.
                        Most cookies are "session cookies,"meaning that they are automatically deleted from your hard drive at the endof asession. Additionally,
                        you may encounter "cookies" or other similar devices on certain pages of the Site that are placed by third parties. We do not control the use of cookies by third parties</li>
                    <li style={{ marginTop: "30px" }}>If you choose to transact on the Site or the BUZZ App, we may collect information about your web behavior,
                        such as your mobile number, your personal information which user have shared,
                        a credit / debit card number and a credit / debit card expiration date and/ or other payment instrument details.</li>
                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}>Information Sharing and Disclosure</span><br />
                        We do not share any of your personal information to any third parties. We may disclose your personal information to affiliates who work with us to
                        provide products and services requested by you. We will share personal information for these purposes only with affiliates whose privacy policies are
                        consistent with ours or who agree to abide by our policies with respect to personal information. We may
                        otherwise disclose your personal information when: We have your express consent to share the information for a specified purpose.
                        We need to respond to subpoenas, court orders or such other legal process. We need to protect the personal safety of
                        the users of our Site, BUZZ app or defend the rights or property. We find that your actions on our Sites violate the Terms of Use document
                        or any of our usage guidelines for specific products or services</li>
                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}>BUZZ App Privacy Policy</span><br />
                        This Privacy Policy describes the policies and procedures of KONNXT PRIVATE LIMITED (“BUZZ”) on the collection, use and disclosure of your information
                        when you use the BUZZ Application on your mobile phone. BUZZ app collects and uses information as described in "Collection and Use of Personal Information" section.
                        We will not use or share your information with anyone except as described in this Privacy Policy. This Privacy Policy does not apply to information we collect by other means
                        (including offline) or from other sources. The terms "we", "us", and “our website” refer to BUZZ App or
                        <span onClick={() => {
                            window.open("https://www.konnxt.com", '_blank')
                        }} style={{ color: '#706dea', textDecoration: "underline", cursor: 'pointer', fontWeight: 600 }}> www.konnxt.com</span> or
                        <span style={{ color: '#706dea', textDecoration: "underline", cursor: 'pointer', fontWeight: 600 }} > buzz.konnxt.com</span> and the terms"you" and
                        "your" refer to you, as a user of one of our services. The term "personal
                        information" means information that you provide to us which personally identifies you to be contacted or identified, such as your name, phone number,
                        email address, and any other data that is tied to such information.
                    </li>

                    <li style={{ marginTop: "30px" }} ><span style={{ fontWeight: 600 }}>How We Share Your Information</span><br /><br />
                        <span style={{ fontWeight: 600 }}>{"a)"} Personally Identifiable Information:</span><br />

                        <span style={{ fontWeight: 600 }}>I.</span> We automatically read & collect your email addresses available on your phone. We need email addresses to send you email for updates, Invoices & other communications. We do not share this with any third parties.

                        <br /><br />


                        <span style={{ fontWeight: 600 }}>{"b)"} Non-Personally Identifiable Information</span><br />

                        <span style={{ fontWeight: 600 }}>I. </span>
                        We may aggregate and/or anonymize information collected through the Service so that the information does not identify you.
                        We may use aggregated, anonymized, and other non-personally identifiable information
                        for any purpose, including for research and marketing purposes, and our use and disclosure of such information is not subject to any
                        restrictions under this Privacy Policy.

                        <br /><br />


                        <span style={{ fontWeight: 600 }}>II. </span>
                        KONNXT PRIVATE LIMITED (BUZZ) may allow third-party ad servers or ad networks to serve advertisements on the Service and on third-party services. These third-party ad servers or ad networks use technology to send, directly to your browser, the advertisements and links that appear on our property. They automatically receive your IP address when this happens. They may also use other technologies (such as cookies, JavaScript, or web beacons) to measure the effectiveness of their advertisements and to personalize the advertising content. KONNXT PRIVATE LIMITED (BUZZ) does not provide any personally identifiable information to these third-party ad servers or ad networks without your consent. However, please note that if an advertiser asks KONNXT PRIVATE LIMITED (BUZZ) to show an advertisement to a certain audience and you respond to that advertisement, the advertiser or ad server may conclude that you fit the description of the audience they are tryingtoreach. The KONNXT PRIVATE LIMITED (BUZZ) Privacy Policy does not apply to, and we cannot control the activities of, third-party advertisers. Please consult the respective privacy policies of such advertisers for more information.

                        <br /><br />

                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}>Advertising</span><br />
                        We may use third-party advertising companies to serve ads when you visit our Site. These companies may use information (not including your name, address, email
                        address, or telephone number) about your visits to this and other websites inorderto provide advertisements about goods and services of interest to you.
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}>Consent</span><br />
                        By using the Site and/ or by providing your information, you consent to the collection and use of the information you disclose on the Site in accordance with this Privacy Policy, including but not limited to your consent for sharing your information as per this privacy policy.
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}>Security</span><br />
                        Unfortunately, no data transmission over the Internet can be considered 100% secure. However we use industry-standard encryption to protect data transmissions. We also safeguard your personal information from unauthorized access, through access control procedures, network firewalls and physical security measures.
                    </li>
                </Typography>


                <Divider sx={{ my: 2 }} />


                <Typography fontFamily={"poppins"} fontWeight={700} variant="h5" gutterBottom>
                    PART 2
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    DATA BACKUP FRAMEWORK
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" paragraph>
                    It is the policy of the company to define the backup process for computers / Laptops and other electronic device within the company, which are expected to have their data
                    backed up. The policy is designed to protect company’s data and to ensurethatit can be recovered in the event of an equipment failure, intentional destruction of
                    data, or disaster. This policy applies to all information, critical systems and their data owned and operated by the organization.</Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h7" gutterBottom>
                    Certain Basic Concepts
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'number', pl: 8 }}>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Backup</span><br />
                        The saving of files onto magnetic tape or other offlinemassstorage media for the purpose of preventing loss of dataintheevent of equipment failure or destruction.
                    </li>
                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Archive</span><br />
                        The saving of old or unused files onto magnetic tape or otheroffline mass storage media for the purpose of releasing on-linestorage room                    </li>
                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Restore</span><br />
                        The process of bringing off line storage data back fromtheoffline media and putting it on an online storage systemsuchasa file server.                    </li>
                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Timing</span><br />
                        Full back up is performed monthly on the last week of the month. If for publicholiday/maintenance reason, backups are not performed. They are done onnextworking day.
                        <br />
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Cloud Storage</span><br />
                        Cloud Storage - Backups are performed on Storage service as provisioned on “Amazon Web Services “ - please refer to the privacy policy of AWS and Amazon on 
                        <span onClick={()=> {
                            window.open("https://aws.amazon.com/privacy/", "_blank")
                        }} style={{color:'#706dea', cursor:'pointer', textDecoration:'underline'}}> Amazon Privacy Policy</span> and all data stored and interactions will be maintained on MongoDB Atlas please refer to the privacy policy for the same on <span onClick={()=> {
                            window.open("https://www.mongodb.com/legal/privacy-policy", "_blank")
                        }} style={{color:'#706dea', cursor:'pointer', textDecoration:'underline'}}> MongoDB Privacy Policy</span>.
                        <br />
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Cloud Storage Cleaning</span><br />
                        Cloud Storage cleaning will be done on a monthly basis within the 10
                        th day of the following month. In case of emergency situation cleaning is done as soon as possiblefor the CIO and the concerned team.<br />
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Monthly Backups</span><br />
                        The last week backup tape of every month shall be stored at company’s registered office. Further Backup shall be stored on AWS (any one)
                        <br />
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Responsibility</span><br />
                        The CIO / IT Administrator or delegate a member of the Information Technology(IT)
                        department should perform regular backups. The IT department shall test the ability to restore data from backups.<br />
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Testing</span><br />
                        The ability to restore data from backups shall be tested at least on a monthly/quarterly cycle as per direction of the CIO
                        <br />
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Data Backed Up</span><br />
                        Data to be backed up include the following information:<br /><br />

                        <span style={{ fontWeight: 600 }}> • </span>
                        Sign-up Details (as specified below)
                        <br />
                        <span style={{ fontWeight: 600 }}> • </span>
                        Live Location Details (GPS data)
                        <br />
                        <span style={{ fontWeight: 600 }}> • </span>
                        Messages and Chat Logs
                        <br />
                        <span style={{ fontWeight: 600 }}> • </span>
                        FrontEnd and Backend Codebase including the database
                        <br />
                        <span style={{ fontWeight: 600 }}> • </span>
                        Mail archive
                        <br />
                        <span style={{ fontWeight: 600 }}> • </span>
                        Legacy Application database
                        <br />
                    </li>
                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Restoration</span><br />
                        Users that need files restored must submit a request to the help desk. That includesinformation about the files, folder and its path with reason - As is
                        <br />
                    </li>

                    <li style={{ marginTop: "30px" }}>
                        <span style={{ fontWeight: 600 }}> Data Retention</span><br />
                        Monthly Data backed up on backup tapes to be retained for Eight years unlessspecifically directed by the CAB
                        <br />
                    </li>


                </Typography>

                <Typography mt={"30px"} mb={"40px"} fontFamily={"poppins"} fontWeight={600} fontSize={"14px"}>
                    Explanation – In case of day to day routine activities in relation to Data backup the ultimate competent authority will be any of the executive Director</Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h7" gutterBottom>
                    Changes to this Privacy Policy
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" paragraph mt={"5px"}>
                    We may at any time, without notice to you and in its sole discretion, amend this policy from time to time. Please review this policy periodically. Your continued use of
                    this Site after any such amendments signifies your acceptance thereof.</Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h7" gutterBottom>
                    Grievance Officer
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" paragraph mt={"5px"}>
                    In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below–</Typography>

                <Typography variant="h6" style={{ fontFamily: 'poppins', fontWeight: 700 }} mb={"30px"} align="left" gutterBottom>
                    SOHAM GAIN<br />135-N, KARUNAMOYEE GHAT<br />
                    RD REF-C.PYNE 3/5/12<br />
                    KOLKATA WB 700082
                </Typography>

                <Typography fontFamily={"poppins"} fontWeight={600} variant="h7" gutterBottom>
                    Questions or Suggestions
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" paragraph mt={"5px"}>
                    If you have questions or suggestions about this privacy policy, or your ownpersonal
                    information, please e-mail us at <span style={{ color: '#706dea', fontWeight: 500, textDecoration: 'underline' }} >buzzofficial@konnxt.com</span> </Typography>

                <Divider sx={{ my: 2 }} />


                <Typography fontFamily={"poppins"} fontWeight={700} variant="h5" gutterBottom>
                    SCENARIO 1
                </Typography>
                <Typography fontFamily={"poppins"} fontWeight={600} variant="h6" gutterBottom>
                    Information Collected when SIGNUP
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography fontFamily={"poppins"} fontWeight={400} variant="body1" component="ul" sx={{ listStyle: 'disc', pl: 8 }}>
                    <li><span style={{ fontWeight: 600 }}>Phone Number
                        Google / Facebook Login (through Google and Facebook, company only gets
                        authentication notice)</span></li>
                    <li><span style={{ fontWeight: 600 }}>Name</span></li>
                    <li><span style={{ fontWeight: 600 }}>Date of Birth</span></li>
                    <li><span style={{ fontWeight: 600 }}>Profile Picture (From Phone Storage after asking for user permission)</span></li>
                    <li><span style={{ fontWeight: 600 }}>Location of the User(With User Permission , not disclosed to anyone)</span><br /><br />
                        [We take data in the format for matching:
                        User activity on the app (Whichever options they choose and screen time, usage activity:
                        Matching preference, Buzz line, Profile updating Places where user is most active Download and deletion of the application )]
                        <br /> <br />
                        Each user has access to each user’s profile section which contains:
                    </li>
                    <li><span style={{ fontWeight: 600 }}>Buzz name</span></li>
                    <li><span style={{ fontWeight: 600 }}>Age</span></li>
                    <li><span style={{ fontWeight: 600 }}>Bio</span></li>
                    <li><span style={{ fontWeight: 600 }}>Location (City & Country)</span></li>
                    <li><span style={{ fontWeight: 600 }}>Profession and Interests</span></li>

                </Typography>

                <Typography mt={"30px"} mb={"40px"} fontFamily={"poppins"} fontWeight={600} fontSize={"14px"}>
                    [Company holds rights to use User data keeping their sensitive information intact for
                    Company related activities for Curating Services Channelizing Advertisement]
                </Typography>


            </Box>


        </Box>
    );
};


const ContactUsForm = () => {

    const isMobileScreen = useMediaQuery('(max-width:500px)');
    const isTabScreen = useMediaQuery('(max-width:1150px)')

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');



    const submitDetails = async () => {
        try {
            const response = await fetch('https://api.konnxt.com/web/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, email, message })
            });

            const json = await response.json();

            if (json.success) {
                // Handle successful submission (e.g., display a success message)
                alert('Your message has been sent successfully!');
                setName("")
                setEmail("")
                setMessage("")
            } else {
                // Handle errors (e.g., display an error message)
                alert(json.response);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An unexpected error occurred. Please try again later.');
        }
    };

    return (
        <Box sx={{
            flexDirection: 'column', display: 'flex', background: '#ffffff', borderRadius: "15px", padding: isMobileScreen ? "15px" : "25px", alignSelf: 'stretch',
            marginInline: isMobileScreen ? "10px" : "0px", width: isMobileScreen ? "auto" : isTabScreen ? "400px" : "500px"
        }}>

<Typography style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "12px", color: "#303030", marginLeft: "3px", marginTop: "30px" }}>Name</Typography>
            <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                    borderColor: '#ffffff', background: '#ffffff', borderRadius: "4px", marginTop: "5px", alignSelf: 'stretch'
                }}
                inputProps={{
                    style: {
                        paddingTop: "13px", paddingBottom: "13px", paddingInline: "15px", borderRadius: "4px", fontFamily: 'poppins', fontSize: '14px',
                    }
                }}
                inputMode={'text'}
                placeholder='Enter your name' 
            />

            <Typography style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "13px", color: "#303030", marginLeft: "3px", marginTop: "20px" }}>Email</Typography>
            <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                    borderColor: '#ffffff', background: '#ffffff', borderRadius: "4px", marginTop: "5px",
                }}
                inputProps={{
                    style: {
                        paddingTop: "13px", paddingBottom: "13px", paddingInline: "15px", borderRadius: "4px", fontFamily: 'poppins', fontSize: '14px',
                    }
                }}
                inputMode={'email'}
                placeholder='Enter your email address'
            />

            <Typography style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "13px", color: "#303030", marginLeft: "3px", marginTop: "20px" }}>Message</Typography>
            <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                    borderColor: '#ffffff', background: '#ffffff', borderRadius: "4px", marginTop: "5px",
                    verticalAlign: 'top', textAlign: 'left', flex: 1,
                }}
                inputProps={{
                    style: {
                        paddingTop: "13px", paddingBottom: "13px", borderRadius: "4px", fontFamily: 'poppins', fontSize: '14px',
                        textAlign: 'left', verticalAlign: 'top', minHeight: "130px"
                    }
                }}
                multiline
                placeholder='Enter your message'
            />

            <Typography
                onClick={submitDetails}
                style={{
                    cursor: 'pointer',
                    color: '#ffffff', fontFamily: 'poppins', fontSize: "14px", background: '#333946', borderRadius: "4px", fontWeight: 500,
                    marginTop: "40px", textAlign: 'center', paddingBottom: "10px", paddingTop: "10px"
                }} 
            >
                Submit
            </Typography>

        </Box>
    )
}


const BuzzContactUs = ({ route }) => {

    const isMobileScreen = useMediaQuery('(max-width:1000px)');
    const isTabScreen = useMediaQuery('(max-width:1500px)')
    return (
        <Box sx={{ mt: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', background: "#333946", paddingTop: isMobileScreen ? "40px" : "60px" }}>


            <Box sx={{
                alignSelf: 'stretch', marginInline: isMobileScreen ? "20px" : isTabScreen ? "50px" : "250px", flexDirection: 'row', display: 'flex',
                marginTop: isMobileScreen ? "0px" : "40px", marginBottom: "80px",
                justifyContent: isMobileScreen ? 'center' : "space-between", alignItems: 'center',
            }}>

                <Box sx={{ flexDirection: 'column', alignItems: isMobileScreen ? 'center' : 'flex-start', display: 'flex', }}>

                    <Typography fontFamily={"poppins"} fontWeight={500} variant={isMobileScreen ? "h5" : "h4"} component="h2" gutterBottom textAlign={isMobileScreen ? "center" : "left"} color={"#ffffff"}>
                        Get in touch with us
                    </Typography>
                    <Typography textAlign={isMobileScreen ? "center" : "left"} fontFamily={"poppins"} fontWeight={300} color={"#ffffff90"} fontSize={isMobileScreen ? "13px" : "15px"} component="h2" maxWidth={"350px"}>
                        Fill out the form below or schedule a meeting with us at your convenience.
                    </Typography>

                    <Box sx={{
                        height: "1px", alignSelf: 'stretch', background: "#ffffff10", marginTop: "30px", maxWidth: isMobileScreen ? "100%" : "300px",
                        marginBottom: isMobileScreen ? "20px" : "0px"
                    }} />

                    {
                        isMobileScreen && (
                            <ContactUsForm />
                        )
                    }

                    <Typography fontFamily={"poppins"} fontWeight={400} color={"#ffffff"} fontSize={"16px"} mt={"30px"} component="h2" maxWidth={"500px"}>
                        You can also contact us via following below
                    </Typography>


                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginTop: "30px", cursor: 'pointer' }}>
                        <img src={require("../../assets/images/email.svg")} style={{ width: '16px', height: "16px", filter: "invert()", marginTop: '2px' }} />
                        <Typography style={{
                            fontFamily: 'poppins',
                            fontSize: '14px',
                            fontWeight: 300,
                            maxWidth: "310px",
                            color: '#e5e5e5',
                            marginLeft: "10px"
                        }} >support@konnxt.com</Typography>
                    </Box>

                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginTop: "20px", cursor: 'pointer' }}>
                        <img src={require("../../assets/images/location.svg")} style={{ width: '16px', height: "16px", filter: "invert()", marginTop: '2px' }} />
                        <Typography style={{
                            fontFamily: 'poppins',
                            fontSize: '14px',
                            fontWeight: 300,
                            maxWidth: "310px",
                            color: '#e5e5e5',
                            marginLeft: "10px",
                            textAlign: isMobileScreen ? "center" : "left"
                        }} >135/N, Karunamoyee Ghat Rd, Sarat Pally, Kazipara, Kolkata, West Bengal</Typography>
                    </Box>


                    <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: "40px" }}>
                        <img onClick={() => {
                            window.open('https://www.facebook.com/buzz.konnxt?mibextid=ZbWKwL', '_blank');
                        }} src={require("../../assets/images/facebook.svg")} style={{
                            width: '24px', height: "24px", filter: "invert()", marginTop: '2px', marginRight: "25px",
                            cursor: 'pointer'
                        }} />
                        <img onClick={() => {
                            window.open('https://www.instagram.com/buzzonspot/', '_blank');
                        }} src={require("../../assets/images/insta.svg")} style={{
                            width: '24px', height: "24px", filter: "invert()", marginTop: '2px',
                            cursor: "pointer"
                        }} />


                    </Box>

                    <Box sx={{ marginTop: isMobileScreen ? '10px' : '50px', flexDirection: isMobileScreen ? 'column' : 'row', display: 'flex', marginLeft: isMobileScreen ? "0px" : "-10px" }}>
                        <DownloadButton onClick={() => {
                            window.open('https://apps.apple.com/in/app/buzz-meet-people-around-you/id6627343452', '_blank');
                        }} style={{
                            background: '#ffffff'
                        }} variant="contained" color="#303030">
                            <img src={require("../../assets/images/apple.svg")} style={{ width: '15px', height: "15px" }} />
                            <Typography style={{
                                fontFamily: 'poppins',
                                fontSize: isMobileScreen ? '13px' : '13px',
                                fontWeight: 400,
                                color: '#000000',
                                marginLeft: "10px"
                            }} >Download from App Store</Typography>
                        </DownloadButton>
                        <DownloadButton onClick={() => {
                            window.open('https://play.google.com/store/apps/details?id=com.buzz.project', '_blank');
                        }} variant="contained" style={{
                            border: "1px solid #ffffff"
                        }} color="#303030">
                            <img src={require("../../assets/images/play-store-dark.svg")} style={{ width: '15px', height: "15px", filter: "invert()" }} />
                            <Typography style={{
                                fontFamily: 'poppins',
                                fontSize: isMobileScreen ? '13px' : '13px',
                                fontWeight: isMobileScreen ? 500 : 400,
                                marginLeft: "10px",
                                flex: 1,
                                textAlign: 'center',
                                color: 'white'
                            }}>Get it on Google Play</Typography>
                        </DownloadButton>

                    </Box>



                    <Box sx={{ height: "1px", alignSelf: 'stretch', background: "#ffffff10", marginTop: "30px", maxWidth: "300px" }} />

                    <Box sx={{ marginTop: "20px", flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                        <Typography
                            onClick={() => {
                                window.location.href = `${BASE_URL}/terms-of-service`
                            }}
                            style={{ cursor: 'pointer', color: "white", textDecoration: "underline", fontFamily: 'poppins', fontWeight: 300, fontSize: "12px", marginRight: "20px" }} >
                            Terms of Service</Typography>
                        <Typography
                            onClick={() => {
                                window.location.href = `${BASE_URL}/privacy-policy`
                            }}
                            style={{ color: "white", cursor: 'pointer', textDecoration: "underline", fontFamily: 'poppins', fontWeight: 300, fontSize: "12px" }} >
                            Privacy Policy</Typography>

                    </Box>

                </Box>

                {
                    !isMobileScreen && (
                        <ContactUsForm />
                    )
                }


            </Box>

            <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginBottom: "10px" }}>
                <Typography sx={{ color: '#ffffff90', fontFamily: 'poppins', fontWeight: 300, fontSize: "12px", }}>
                    Copyright © 2022. Powered by </Typography>

                <img style={{ width: "40px", height: "20px", marginLeft: "5px" }} src={require("../../assets/images/konnxtLogo.png")} />
            </Box>


        </Box>
    );
};





const PrivacyPolicy = () => {
    return (
        <>
            <BuzzHeader />
            <Box sx={{ height: "1px", alignSelf: 'stretch', background: '#aaaaaa20' }} />
            <HeroSection />
            <BuzzContactUs />
        </>
    );
};

export default PrivacyPolicy;
