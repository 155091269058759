import React, { useEffect, useState } from 'react'
import { Box, List, ListItem, Rating, Typography } from "@mui/material";
import Header from '../Header/Header';
import banner from '../../assets/images/banner1.png'
import Products from '../Items/products';
import { productsData } from '../sample/sample_products';


const Home = () => {

    const [products, setProducts] = useState(productsData)
    const [shuffledProducts, setShuffledProducts] = useState([])

    useEffect(() => {
        const shuffled = shuffleArray([...products]); // Spread to avoid mutating the original array
        setShuffledProducts(shuffled);
    }, [])

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
        return array;
    }

    return (
        <Box sx={{ flexDirection: 'column', display: 'flex', alignSelf: 'stretch', alignItems: 'stretch', }}>
            <Header />
            <Box sx={{ alignSelf: 'stretch', height: "1px", background: "#aaaaaa20" }} />
            <Box sx={{ paddingX: "160px", flexDirection: 'column', display: 'flex' }} >
                <Box sx={{ height: "320px", alignSelf: 'stretch', borderRadius: "10px", marginTop: "20px", overflow: 'hidden', background: '#aaaaaa20', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', paddingX: "75px", paddingY: "15px", height: "100%", flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                        <Typography style={{ fontFamily: 'Poppins', fontWeight: 700, fontSize: "32px", userSelect: 'none' }}>Get Upto 40% of on <br />Headphones</Typography>
                        <Typography style={{
                            fontFamily: "Poppins", fontWeight: 500, background: '#5d5491', paddingInline: "25px", paddingTop: "7px", paddingBottom: "7px",
                            alignSelf: 'flex-start', color: '#ffffff', borderRadius: "100px", border: '2px solid #ffffff', fontSize: "15px", marginTop: "25px", userSelect: 'none'
                        }}>Shop Headphones Now</Typography>
                    </Box>
                    <img src={banner} style={{ height: "320px", width: "100%", objectFit: 'cover' }} />
                </Box>


                <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: "50px" }}>

                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, overflowX: 'auto', }}>
                        <Box sx={styles.catItem}>
                            <Typography style={styles.catText}>All</Typography>
                        </Box>
                        <Box sx={styles.catItem}>
                            <Typography style={styles.catText}>Headphones</Typography>
                        </Box>
                        <Box sx={styles.catItem}>
                            <Typography style={styles.catText}>Mobile Covers</Typography>
                        </Box>
                        <Box sx={styles.catItem}>
                            <Typography style={styles.catText}>T-Shirts</Typography>
                        </Box>
                        <Box sx={styles.catItem}>
                            <Typography style={styles.catText}>Power Banks</Typography>
                        </Box>
                        <Box sx={styles.catItem}>
                            <Typography style={styles.catText}>Toys</Typography>
                        </Box>
                    </Box>

                    <Box sx={{
                        paddingX: '18px', minWidth: '70px',
                        border: '1px solid #aaaaaa80',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        paddingY: "4px",
                        borderRadius: "100px",
                        flexDirection: 'row',
                        cursor: 'pointer'
                    }}>
                        <Typography style={styles.catText}>Sort Items by ▾</Typography>
                    </Box>
                </Box>


                <Box sx={{
                    flexDirection: 'row', display: 'flex', alignSelf: 'stretch'
                }} >

                    <Box sx={{ flexDirection: 'column', display: 'flex', flex: 1 }}>
                        <Products products={shuffledProducts} />
                    </Box>

                    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                        <Box sx={{
                            width: '300px', border: "1px solid #aaaaaa20", borderRadius: '10px', height: "500px", marginTop: "35px",
                            flexDirection: 'column', display: 'flex', paddingX: "15px", paddingY: "15px"
                        }}>

                            <Typography style={{
                                fontFamily: 'poppins', fontWeight: 600, fontSize: "13px"
                            }}>Newly Added Products</Typography>

                            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: "30px" }}>
                                <img src={"https://yourdesignstore.s3.amazonaws.com/uploads/yds/productImages/full/1632490432614dd3c096612Kid_OR.jpg"}

                                    style={{ width: "80px", height: "80px", borderRadius: "10px" }}

                                />

                                <Box sx={{ flexDirection: 'column', display: 'flex', flex: 1, marginLeft: "10px" }}>

                                    <Typography variant="h6" style={{
                                        fontFamily: 'poppins', fontSize: "12px", marginTop: "1px", display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2, // Limit to 2 lines
                                        textOverflow: 'ellipsis',
                                    }}>White oversized tshirt for father and son, Perfect combination</Typography>

                                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: "1px", alignSelf: 'stretch', }}>
                                        <Rating value={4} sx={{
                                            '& .MuiRating-icon': {
                                                fontSize: '12px',  // Set custom font size for the stars
                                            },
                                        }} />
                                        <Typography variant="body1" style={{
                                            fontFamily: 'poppins', fontWeight: 400, fontSize: "9px", lineHeight: 1, marginTop: "1.5px",
                                            color: '#000000', marginLeft: "3px", alignSelf: 'center'
                                        }}>{"4.5 (293 ratings)"}</Typography>

                                    </Box>

                                    <Box sx={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', marginTop: "8px", }}>
                                        <Typography variant="body1" style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "13px", 
                                            color: '#707070', textDecoration: 'line-through' }}>₹834</Typography>
                                        <Typography variant="body1" style={{ fontFamily: 'poppins', fontWeight: 700, fontSize: "13px", 
                                            color: '#000000', marginLeft: "10px" }}>₹560</Typography>


                                    </Box>

                                </Box>
                            </Box>

                            <Box sx={{alignSelf:'stretch', height:"1px", background:'#aaaaaa40', marginTop: "20px"}} />

                            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: "10px" }}>
                                <img src={"https://yourdesignstore.s3.amazonaws.com/uploads/yds/productImages/full/1632490432614dd3c096612Kid_OR.jpg"}

                                    style={{ width: "80px", height: "80px", borderRadius: "10px" }}

                                />

                                <Box sx={{ flexDirection: 'column', display: 'flex', flex: 1, marginLeft: "10px" }}>

                                    <Typography variant="h6" style={{
                                        fontFamily: 'poppins', fontSize: "12px", marginTop: "1px", display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2, // Limit to 2 lines
                                        textOverflow: 'ellipsis',
                                    }}>White oversized tshirt for father and son</Typography>

                                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: "1px", alignSelf: 'stretch', }}>
                                        <Rating value={4} sx={{
                                            '& .MuiRating-icon': {
                                                fontSize: '12px',  // Set custom font size for the stars
                                            },
                                        }} />
                                        <Typography variant="body1" style={{
                                            fontFamily: 'poppins', fontWeight: 400, fontSize: "9px", lineHeight: 1, marginTop: "1.5px",
                                            color: '#000000', marginLeft: "3px", alignSelf: 'center'
                                        }}>{"4.5 (293 ratings)"}</Typography>

                                    </Box>

                                    <Box sx={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', marginTop: "8px", }}>
                                        <Typography variant="body1" style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "13px", 
                                            color: '#707070', textDecoration: 'line-through' }}>₹834</Typography>
                                        <Typography variant="body1" style={{ fontFamily: 'poppins', fontWeight: 700, fontSize: "13px", 
                                            color: '#000000', marginLeft: "10px" }}>₹560</Typography>


                                    </Box>

                                </Box>
                            </Box>

                            <Box sx={{alignSelf:'stretch', height:"1px", background:'#aaaaaa40', marginTop: "20px"}} />

                            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: "10px" }}>
                                <img src={"https://yourdesignstore.s3.amazonaws.com/uploads/yds/productImages/full/1632490432614dd3c096612Kid_OR.jpg"}

                                    style={{ width: "80px", height: "80px", borderRadius: "10px" }}

                                />

                                <Box sx={{ flexDirection: 'column', display: 'flex', flex: 1, marginLeft: "10px" }}>

                                    <Typography variant="h6" style={{
                                        fontFamily: 'poppins', fontSize: "12px", marginTop: "1px", display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2, // Limit to 2 lines
                                        textOverflow: 'ellipsis',
                                    }}>White oversized tshirt for father and son</Typography>

                                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: "1px", alignSelf: 'stretch', }}>
                                        <Rating value={4} sx={{
                                            '& .MuiRating-icon': {
                                                fontSize: '12px',  // Set custom font size for the stars
                                            },
                                        }} />
                                        <Typography variant="body1" style={{
                                            fontFamily: 'poppins', fontWeight: 400, fontSize: "9px", lineHeight: 1, marginTop: "1.5px",
                                            color: '#000000', marginLeft: "3px", alignSelf: 'center'
                                        }}>{"4.5 (293 ratings)"}</Typography>

                                    </Box>

                                    <Box sx={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', marginTop: "8px", }}>
                                        <Typography variant="body1" style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "13px", 
                                            color: '#707070', textDecoration: 'line-through' }}>₹834</Typography>
                                        <Typography variant="body1" style={{ fontFamily: 'poppins', fontWeight: 700, fontSize: "13px", 
                                            color: '#000000', marginLeft: "10px" }}>₹560</Typography>


                                    </Box>

                                </Box>
                            </Box>
                            <Box sx={{alignSelf:'stretch', height:"1px", background:'#aaaaaa40', marginTop: "20px"}} />

                            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: "10px" }}>
                                <img src={"https://yourdesignstore.s3.amazonaws.com/uploads/yds/productImages/full/1632490432614dd3c096612Kid_OR.jpg"}

                                    style={{ width: "80px", height: "80px", borderRadius: "10px" }}

                                />

                                <Box sx={{ flexDirection: 'column', display: 'flex', flex: 1, marginLeft: "10px" }}>

                                    <Typography variant="h6" style={{
                                        fontFamily: 'poppins', fontSize: "12px", marginTop: "1px", display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2, // Limit to 2 lines
                                        textOverflow: 'ellipsis',
                                    }}>White oversized tshirt for father and son</Typography>

                                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginTop: "1px", alignSelf: 'stretch', }}>
                                        <Rating value={4} sx={{
                                            '& .MuiRating-icon': {
                                                fontSize: '12px',  // Set custom font size for the stars
                                            },
                                        }} />
                                        <Typography variant="body1" style={{
                                            fontFamily: 'poppins', fontWeight: 400, fontSize: "9px", lineHeight: 1, marginTop: "1.5px",
                                            color: '#000000', marginLeft: "3px", alignSelf: 'center'
                                        }}>{"4.5 (293 ratings)"}</Typography>

                                    </Box>

                                    <Box sx={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', marginTop: "8px", }}>
                                        <Typography variant="body1" style={{ fontFamily: 'poppins', fontWeight: 500, fontSize: "13px", 
                                            color: '#707070', textDecoration: 'line-through' }}>₹834</Typography>
                                        <Typography variant="body1" style={{ fontFamily: 'poppins', fontWeight: 700, fontSize: "13px", 
                                            color: '#000000', marginLeft: "10px" }}>₹560</Typography>


                                    </Box>

                                </Box>
                            </Box>

                        </Box>
                    </Box>


                </Box>



            </Box>

        </Box>
    )
}

const styles = {

    catItem: {
        paddingX: '18px', minWidth: '70px',
        background: '#aaaaaa20',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        paddingY: "5px",
        borderRadius: "100px",
        flexDirection: 'row',
        cursor: 'pointer'
    },
    catText: {
        fontFamily: 'Poppins', fontWeight: 500, fontSize: "15px", color: "#000000"
    }

}

export default Home;