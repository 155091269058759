import React from 'react'
import { Box, TextField, Typography } from "@mui/material";
import logo from '../../assets/images/logo.svg'
import cart from '../../assets/images/cart.svg'
import person from '../../assets/images/person.svg'

const link = document.createElement("link");
link.href = "https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
link.rel = "stylesheet";
document.head.appendChild(link);

const Header = () => {

    return (

        <Box sx={{
            flexDirection: 'column', display: 'flex', alignItems:'stretch',
        }}>
            
            <Box sx={{
                flexDirection: 'row', display: 'flex', alignItems: 'center', paddingY: "20px", marginX: "10px",
                justifyContent: 'space-between', alignSelf: 'stretch', paddingX: "150px"
            }}>
                <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                    <img style={{ width: 100, height: 30 }} src={logo} />


                </Box>


                <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                    <Typography style={{ color: "#000000", fontSize: "14px", marginLeft: "50px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1 }} >Categories</Typography>
                    <Typography style={{ color: "#000000", fontSize: "14px", marginLeft: "25px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1 }} >Deals</Typography>
                    <Typography style={{ color: "#000000", fontSize: "14px", marginLeft: "25px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1 }} >New Arrival</Typography>
                    <Typography style={{ color: "#000000", fontSize: "14px", marginLeft: "25px", fontFamily: "Poppins", cursor: 'pointer', lineHeight: 1 }} >Delivery</Typography>
                    <input placeholder='Search Product' style={{
                        paddingTop: "8px", paddingBottom: "8px",
                        marginLeft: "40px", paddingInline: '13px',
                        minWidth: "250px", maxWidth: "300px",
                        background: '#aaaaaa20', outline: 'none',
                        fontFamily: "Poppins",
                        borderRadius: "100px", borderColor: '#aaaaaa00'
                    }} />

                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: "20px", cursor: 'pointer' }}>
                        <img style={{ width: "18px", height: "18px" }} src={person} />
                        <Typography style={{ color: "#000000", fontSize: "13px", marginLeft: "5px", fontFamily: "Poppins", fontWeight: 600, lineHeight: 1 }} >Account</Typography>
                    </Box>
                    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', marginLeft: "20px", cursor: 'pointer' }}>
                        <img style={{ width: "18px", height: "18px" }} src={cart} />
                        <Typography style={{ color: "#000000", fontSize: "13px", marginLeft: "5px", fontFamily: "Poppins", fontWeight: 600, lineHeight: 1 }} >My Cart</Typography>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

export default Header;